import {
  getFieldName,
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCnpj,
  JarvislyCpf,
  JarvislyCpfCnpj,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyRadioButtons,
  JarvislySegmented,
  setField,
  JarvislySelect,
  JarvislyTitle,
  JarvislyWebsite,
  JarvislyPhone,
  JarvislyEmail,
  JarvislyDatePicker,
  JarvislySelectGender,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import {
  clone,
  isValidCnpj,
  isValidCpf,
  isValidCpfCnpj,
  isValidEmail,
  isValidPhoneNumber,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import { Badge, Button, Col, Collapse, Flex, Row, Tooltip } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useContext, useEffect, useState } from 'react';
import { EditOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JarvislyContactForm from './JarvislyContactForm';
import { CrmParametersContext } from 'modules/app-views/applekkus/crm/Parameters/ParametersProvider';
import JarvislyAddressForm from './JarvislyAddressForm';
import JarvislyNoteForm from './JarvislyNoteForm';

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyEntityForm = props => {
  // providers context ---------------------------------------------------------
  const { elRefs, form, formRefreshed, requiredFields } =
    useContext(JarvislyFormContext);
  const { refreshDocument } = useContext(ModuleContext);
  const { setShowBusinessAreaModal, setSelectedRecord } =
    useContext(CrmParametersContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showTitle,
    // showCpf,
    // showBirthdate = true,
    // showGender = true,
    entityPersonType = 'manual', // 'automatic', 'manual', 'company', 'person'
    entityPersonDefault = 'person', //  'company', 'person'
    document,
    selectedTab,
    thisTab,
    // initTab,

    selectable = false,
    // _id,
    // mode,
    selectedEntity,
    setSelectedEntity,
    mode,

    originRegisteredLabel = 'registered_entity',
    originNewLabel = 'new_entity',
    searchEntityLabel = 'search',

    showCpfCnpj = true,
    cpfCnpjFirst = false,
    additionalFieldsFixed = false,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile('sm');
  const segmentedOptions = buildSegmentedOptions();
  const { selectedModule, parameters } = useSelector(s => s.moduleSlice);
  const navigate = useNavigate();
  const phoneOrEmailRequired = requiredFields?.includes(
    context ? [context, 'phoneEmail'] : 'phoneEmail',
  );
  // const isDisabled = isWorking || mode === 'view';

  // const requiredCpfCnpj = requiredFields?.includes('cpfCnpj');

  // component states ----------------------------------------------------------
  const [, setBillingEmailsOptions] = useState([]);
  const [dataType, setDataType] = useState(null);
  const [entityOrigin, setEntityOrigin] = useState(
    selectable ? 'registered_entity' : 'new_entity',
  );
  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);

  // local variables II --------------------------------------------------------
  const cpfCnpjLabel = buildCpfCnpjLabel();

  /*
  const childProps = {
    ...props,
    document,
    // selectedRecord,
    // setSelectedRecord,
    // showBusinessAreaModal,
    // setShowBusinessAreaModal,
  };
*/
  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const type =
      document?.[getFieldName('dataType', context)] || entityPersonDefault;

    setDataType(type);

    setField('dataType', type, form, context);
  }, [entityPersonDefault, entityOrigin, document, formRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTab !== thisTab) return;

    if (entityOrigin === 'registered_entity') {
      goFocus(elRefs, getFieldName('selectedEntity', context), 400);
    } else {
      if (entityPersonType === 'automatic' || cpfCnpjFirst) {
        goFocus(elRefs, getFieldName('cpfCnpj', context), null, 400);
      } else {
        goFocus(elRefs, getFieldName('name', context), null, 400);
      }
    }
  }, [selectedTab, entityOrigin, dataType, entityPersonType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const dataTypeField = getFieldName('dataType', context, document);

    setField(
      'entityOrigin',
      selectable ? 'registered_entity' : 'new_entity',
      form,
      context,
    );

    if (document?.[dataTypeField]) setDataType(document[dataTypeField]);
    setSelectedEntity(document?.__entity || null);
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsPhoneRequired(requiredFields?.includes('phoneEmail'));
    setIsEmailRequired(requiredFields?.includes('phoneEmail'));
  }, [requiredFields]);

  // methods -------------------------------------------------------------------
  const onChangeEntityOrigin = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    // form.setFieldValue([context], {});

    if (value === 'registered_entity') {
      goFocus(elRefs, context ? [context, 'selectedEntity'] : 'selectedEntity');
    } else {
      if (entityPersonType === 'automatic') {
        goFocus(elRefs, context ? [context, 'cpfCnpj'] : 'cpfCnpj');
      } else {
        goFocus(elRefs, context ? [context, 'name'] : 'name');
      }
    }

    setEntityOrigin(value);
    setSelectedEntity(null);
    setBillingEmailsOptions([]);
  };

  const onEntitySelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setEntityOrigin('registered_entity');
    setSelectedEntity(doc || null);
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
    form.validateFields([context ? [context, 'phone'] : 'phone']);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const cpfCnpj = returnOnlyNumbers(value);

    if (
      (entityPersonType === 'automatic' && !isValidCpfCnpj(cpfCnpj)) ||
      (entityPersonType === 'company' && !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'person' && !isValidCpf(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'company' &&
        !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'person' &&
        !isValidCpf(cpfCnpj))
    ) {
      return;
    }

    if (!isValidCpfCnpj(cpfCnpj)) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) {
      setDataType(isValidCnpj(cpfCnpj) ? 'company' : 'person');
      return true;
    }

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (context) {
          form.setFieldValue([context, fieldName], '');
        } else {
          form.setFieldValue(fieldName, '');
        }

        // if (fieldName && elRefs) goFocus(elRefs, fieldName, 'all');
        return false;
      },

      onOk: async () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (!selectable) {
          const route = selectedModule?.routes.find(r =>
            r?.includes(selectedModule?.urlForm || selectedModule?.url),
          );

          if (route) {
            const newUrl = route.replace(':_id', doc?._id);
            navigate(newUrl);
          }

          refreshDocument(doc?._id).then();
        } else {
          const value = clone(doc);
          value.key = doc._id;
          await onEntitySelect(value);
        }

        setDataType(doc.dataType || 'company');
        if (fieldName && elRefs) goFocus(elRefs, fieldName, 'all');
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  const onChangeValidateRequired = () => {
    if (!phoneOrEmailRequired) {
      setIsPhoneRequired(
        requiredFields?.includes(context ? [context, 'phone'] : 'phone'),
      );
      setIsEmailRequired(
        requiredFields?.includes(context ? [context, 'email'] : 'email'),
      );

      return;
    }

    const phone = form?.getFieldValue(context ? [context, 'phone'] : 'phone');
    const isPhoneValid = isValidPhoneNumber(phone);
    const email = form?.getFieldValue(context ? [context, 'email'] : 'email');
    const isEmailValid = isValidEmail(email);

    setIsPhoneRequired(!isEmailValid);
    setIsEmailRequired(!isPhoneValid);

    if (isPhoneValid)
      form?.validateFields([context ? [context, 'email'] : 'email']);
    if (isEmailValid)
      form?.validateFields([context ? [context, 'phone'] : 'phone']);
  };

  const onAddBusinessArea = () => {
    setSelectedRecord(null);
    setShowBusinessAreaModal(true);
  };
  const onBusinessAreaOrigin = record => {
    setSelectedRecord(record); // Passa o registro completo
    setShowBusinessAreaModal(true);
  };

  const onBusinessAreaChange = value => {
    setSelectedBusinessId(value); // Atualiza o estado quando a opção é selecionada
  };

  // local variables III -------------------------------------------------------
  const businessAreaOptions = buildBusinessAreaOptions();

  // UI COMPONENTS -------------------------------------------------------------
  const Title = props => {
    return showTitle ? (
      <JarvislyTitle
        title={typeof showTitle === 'string' ? showTitle : 'personal_data'}
        {...props}
      />
    ) : null;
  };

  // UI COMPONENTS -------------------------------------------------------------
  const SelectedOrigin = props => {
    return (
      <Flex
        justify={isMobile ? 'center' : 'space-between'}
        align="center"
        vertical={isMobile}
        {...props}
      >
        {/* ENTITY ORIGIN */}
        <JarvislyRadioButtons
          context={context}
          name="entityOrigin"
          onChange={onChangeEntityOrigin}
          noLabel
          options={[
            {
              value: 'registered_entity',
              label: translateX(originRegisteredLabel),
            },
            {
              value: 'new_entity',
              label: translateX(originNewLabel),
            },
          ]}
        />
        {/* ENTITY ORIGIN */}

        {/* ENTITY PERSON TYPE */}
        {entityOrigin === 'new_entity' ? (
          entityPersonType === 'manual' ? (
            <Flex
              justify={isMobile ? 'center' : 'space-between'}
              align="center"
              vertical={isMobile}
            >
              <JarvislySegmented
                name="dataType"
                context={context}
                x-no-label
                options={segmentedOptions}
                onChange={v => setDataType(v)}
                block={false}
              />
            </Flex>
          ) : null
        ) : null}
        {/* ENTITY PERSON TYPE */}
      </Flex>
    );
  };

  // UI COMPONENTS -------------------------------------------------------------
  const NameComponent = () => {
    return (
      <Col xs={24} sm={12} lg={showCpfCnpj ? 6 : 8}>
        <JarvislyInput
          context={context}
          // tabIndex={4}
          name="name"
          label={translateX(dataType === 'person' ? 'name_social' : 'company')}
        />
      </Col>
    );
  };

  const NewEntity = props => {
    return (
      <>
        {!selectable && entityPersonType === 'manual' ? (
          <Flex
            justify={isMobile ? 'center' : 'space-between'}
            align="center"
            vertical={isMobile}
            style={{
              display: entityOrigin === 'new_entity' ? 'flex' : 'none',
            }}
          >
            {/* ENTITY TYPE OPTIONS */}
            <JarvislySegmented
              name="dataType"
              context={context}
              x-no-label
              options={segmentedOptions}
              onChange={v => setDataType(v)}
              block={false}
            />
            {/* ENTITY TYPE OPTIONS */}
          </Flex>
        ) : null}

        <Row gutter={ROW_GUTTER} {...props}>
          {!cpfCnpjFirst ? <NameComponent /> : null}

          {/* CPF/CNPJ */}
          <Col
            xs={24}
            sm={12}
            lg={6}
            style={{
              display: showCpfCnpj ? 'block' : 'none',
            }}
          >
            {entityPersonType === 'company' ||
            (entityPersonType === 'manual' && dataType === 'company') ? (
              <JarvislyCnpj
                context={context}
                // tabIndex={5}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'company')}
                onChange={onCpfCnpjChange}
              />
            ) : entityPersonType === 'person' ||
              (entityPersonType === 'manual' && dataType === 'person') ? (
              <JarvislyCpf
                context={context}
                // tabIndex={5}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'person')}
                onChange={onCpfCnpjChange}
              />
            ) : (
              <JarvislyCpfCnpj
                context={context}
                // tabIndex={1}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'person' || x === 'company')}
                onChange={onCpfCnpjChange}
              />
            )}
          </Col>
          {/* CPF/CNPJ */}

          {cpfCnpjFirst ? <NameComponent /> : null}

          {/* AREA OF ACTIVY */}
          <Col xs={24} sm={12} lg={showCpfCnpj ? 6 : 8}>
            {dataType === 'company' ? (
              <JarvislySelect
                label="area_activy"
                name="businessAreaId"
                options={businessAreaOptions}
                context={context}
                x-show-add-button={true}
                x-on-add-button-fn={onAddBusinessArea}
                onChange={onBusinessAreaChange}
              />
            ) : (
              <JarvislyPhone
                context={context}
                required={isPhoneRequired}
                // profile={phoneProfile}
                // label={phoneLabel}
                onChange={onChangeValidateRequired}
              />
            )}
          </Col>
          {/* AREA OF ACTIVY */}

          {/* WEBSITE */}
          <Col xs={24} sm={showCpfCnpj ? 12 : 24} lg={showCpfCnpj ? 6 : 8}>
            {dataType === 'company' ? (
              <JarvislyWebsite context={context} />
            ) : (
              <JarvislyEmail
                context={context}
                required={isEmailRequired}
                // label={emailLabel}
                onChange={onChangeValidateRequired}
              />
            )}
          </Col>
          {/* WEBSITE */}
        </Row>
        {/* CONTACTS */}
        {dataType === 'company' ? (
          <JarvislyContactForm
            {...props}
            showWebsite={false}
            showName
            context={context}
          />
        ) : null}
        {/* CONTACTS */}
      </>
    );
  };

  const AdditionalFields = () => {
    return (
      <>
        <Row gutter={ROW_GUTTER}>
          {/* FULL NAME */}
          <Col xs={24} sm={12}>
            <JarvislyInput
              context={context}
              name="fullName"
              label={translateX(
                dataType === 'person' ? 'full_name' : 'company_name',
              )}
            />
          </Col>
          {/* FULL NAME */}

          {/* GENDER */}
          <Col xs={24} sm={6}>
            <JarvislySelectGender context={context} />
          </Col>
          {/* GENDER */}

          {/* BIRTHDATE */}
          <Col xs={24} sm={6}>
            <JarvislyDatePicker
              context={context}
              name="birthdate"
              label={translateX(
                dataType === 'person' ? 'birthdate' : 'foundation_date',
              )}
            />
          </Col>
          {/* BIRTHDATE */}
        </Row>

        {/* ADDRESS */}
        <JarvislyAddressForm {...props} />
        {/* ADDRESS */}

        {/* NOTE */}
        <JarvislyNoteForm {...props} />
        {/* NOTE */}
      </>
    );
  };

  // local variables IV --------------------------------------------------------
  const additionalFields = buildAdditionalFields();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Title />
      <SelectedOrigin
        style={{
          display: selectable ? 'flex' : 'none',
        }}
      />
      <Row
        gutter={ROW_GUTTER}
        style={{
          display: entityOrigin === 'registered_entity' ? 'flex' : 'none',
        }}
      >
        {/* ENTITY */}
        <Col xs={24} md={12}>
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label={searchEntityLabel}
            required={entityOrigin === 'registered_entity'}
            tooltip="Nome, Telefone, Email ou Documento"
            // disabled={
            //   isDisabled || (document?.status && document.status !== 'new')
            // }
            x-data-profile={[
              'customer',
              'provider',
              'collaborator',
              'resident',
              'operator',
            ]}
            x-on-select={onEntitySelect}
          />
        </Col>
        {/* ENTITY */}

        {/* CPF/CNPJ */}
        <Col
          xs={24}
          sm={12}
          md={6}
          style={{ display: selectedEntity ? 'block' : 'none' }}
        >
          <JarvislyCpfCnpj context={context} disabled={true} />
        </Col>
        {/* CPF/CNPJ */}

        {/* AREA OF ACTIVY */}
        <Col
          xs={24}
          sm={12}
          md={6}
          style={{ display: selectedEntity ? 'block' : 'none' }}
        >
          <JarvislySelect
            label="area_activy"
            name="businessAreaId"
            options={businessAreaOptions}
            context={context}
            showSearch
            allowClear
            x-show-add-button={true}
            x-on-add-button-fn={onAddBusinessArea}
            onChange={onBusinessAreaChange}
          />
        </Col>
        {/* AREA OF ACTIVY */}
      </Row>

      <NewEntity
        style={{
          display: entityOrigin === 'new_entity' ? 'flex' : 'none',
        }}
      />

      {additionalFieldsFixed ? (
        <AdditionalFields />
      ) : (
        <Collapse
          {...props}
          ghost
          style={{
            display:
              entityOrigin !== 'registered_customer' || selectedEntity
                ? 'flex'
                : 'none',
          }}
          // onChange={keys => {
          //   if (keys?.includes('additional_fields')) {
          //     goFocus(elRefs, 'zip', 'all');
          //   }
          // }}
          items={additionalFields}
          className="no-collapse-padding"
        />
      )}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentedOptions() {
    return [
      {
        label: translateX('legal_person'),
        value: 'company',
        icon: <ShopOutlined />,
      },
      {
        label: translateX('individual_person'),
        value: 'person',
        icon: <UserOutlined />,
      },
    ];
  }

  function buildCpfCnpjLabel() {
    switch (entityPersonType) {
      case 'person':
        return 'cpf';

      case 'company':
        return 'cnpj';

      case 'manual':
        return translateX(dataType === 'company' ? 'cnpj' : 'cpf');

      case 'automatic':
      default:
        return undefined;
    }
  }

  function buildBusinessAreaOptions() {
    return (
      parameters?.entityParameters?.businessArea?.map(businessArea => {
        return {
          value: businessArea._id,
          text: businessArea.label,
          label: (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span key={businessArea._id}>
                  <Badge
                    dot
                    style={{
                      marginRight: 8,
                      color: businessArea?.foreColor,
                      backgroundColor: businessArea?.backColor,
                      marginTop: 4,
                    }}
                  />
                  {businessArea.label}
                </span>

                {selectedBusinessId !== businessArea._id &&
                  mode !== 'view' && ( // Verifica se a opção está selecionada
                    <Tooltip title={translateX('edit')}>
                      <Button
                        size="small"
                        className="m-2"
                        icon={<EditOutlined />}
                        onClick={() => onBusinessAreaOrigin(businessArea)} // Passando o registro completo
                      />
                    </Tooltip>
                  )}
              </div>
            </>
          ),
          disabled: false,
        };
      }) || []
    );
  }

  function buildAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        children: <AdditionalFields />,
      },
    ];
  }
};
export default JarvislyEntityForm;
