import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import { useContext, useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Col, ColorPicker, Form, Modal, Row } from 'antd';
import appService from 'services/appService';
import { TagOutlined } from '@ant-design/icons';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showOriginModal,
    setShowOriginModal,
    setSelectedRecord,
    selectedRecord,
  } = props;

  // local variables I ----------------------------------------------------------

  const title = !selectedRecord?._id
    ? translateX('new_origin')
    : translateX('edit_origin');

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showOriginModal) {
      form.setFieldsValue(selectedRecord); // Preenchendo o formulário com os dados do registro
    }
  }, [selectedRecord, showOriginModal, form]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowOriginModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        className="MODAL-ACTIVY"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showOriginModal}
        width={420}
        okText={translateX('save')}
        onCancel={onClose}
        // cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => form.submit()}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Row>
          <Col span={20} style={{ marginTop: 25 }}>
            <JarvislyInput name="label" x-no-label disabled={false} />
          </Col>

          <Col span={4}>
            <Form.Item
              name="backColor"
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                // disabled={isDisabled}
                // open={open}
                value={form?.getFieldValue('backColor')}
                onChange={backColor => {
                  // setOpen(false);
                  form.setFieldsValue({ backColor });
                }}
                defaultFormat="hex"
                size="small"
                trigger="hover"
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const OriginModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { parameters, refreshParameters, selectedRecord, setShowOriginModal } =
    props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [crmParameters, setEntityParameters] = useState({});

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setEntityParameters(parameters?.crmParameters);
  }, [parameters?.crmParameters]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    // color
    if (body?.backColor) {
      // Convert the color object into a string (hex or rgba) depending on the schema expectation
      if (typeof body.backColor === 'object' && body.backColor.metaColor) {
        body.backColor = `rgba(${body.backColor.metaColor.r}, ${body.backColor.metaColor.g}, ${body.backColor.metaColor.b}, ${body.backColor.metaColor.a})`;
      }
    }
    body._id = selectedRecord?._id;

    if (!crmParameters?._id) {
      const axiosOptions = {
        url: `/v1/crm-parameters`,
        data: { origins: [body] },
      };
      const result = await axiosService.post(axiosOptions);
      setEntityParameters(result[0]);
    } else {
      const axiosOptions = {
        url: `/v1/crm-parameters/add-to-set/${crmParameters?._id}/$push/origins`,
        data: body,
      };
      await axiosService.put(axiosOptions);
    }

    appService.message('s', 'saved_parameters', 'onSave');

    setShowOriginModal(false);
    refreshParameters(true);

    return body;
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-parameters-origin"
        document={selectedRecord}
        requiredFields={['label']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus="label"
        abortComponentSave={true}
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>
    </>
  );
};

export default OriginModal;
