import {
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
  JarvislySwitch,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect, useState
} from 'react';
import { toCapitalCase, translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, List, Modal, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appService from 'services/appService';
import { API_ENTITIES } from 'constants/ApiConstant';
import { TagOutlined } from '@ant-design/icons';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showContactsModal,
    setShowContactsModal,
    isDisabled,
    setSelectedRecord,
    selectedRecord,
    document,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedRecord?._id
    ? translateX('new_contact')
    : translateX('edit_contact');
  // component states -----------------------------------------------------------

  const [communication, setCommunication] = useState({
    mediaCommunication: {
      email: document?.mediaCommunication?.email || false,
      sms: document?.mediaCommunication?.sms || false,
      whatsApp: document?.mediaCommunication?.whatsApp || false,
    },
  });

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showContactsModal) {
      form?.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord, showContactsModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowContactsModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------
  const initialState = buildInitialState();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }        destroyOnClose={true}
        open={showContactsModal}
        width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title="basic_info" />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyInput name="name" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyPhone name="mobile" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyEmail name="email" />
            </Col>
          </Row>
        </Card>
        <Card>
          <JarvislyTitle title="alarm_communication" />
          <List
            itemLayout="horizontal"
            dataSource={initialState}
            renderItem={item => (
              <List.Item>
                <div>
                  <h4 className="mb-0">{item.title}</h4>
                </div>
                <div>
                  <JarvislySwitch
                    name={['mediaCommunication', item.key]}
                    x-no-label
                    className="mt-3"
                    checkedChildren={translateX('yes')}
                    unCheckedChildren={translateX('no')}
                    checked={item.checked}
                    onChange={item.onChange}
                  />
                </div>
              </List.Item>
            )}
          />
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildInitialState() {
    return [
      {
        key: 'email',
        title: translateX('notify_alarm_email'),
        checked: communication.mediaCommunication.email,
        onChange: checked =>
          setCommunication({
            ...communication,
            mediaCommunication: {
              ...communication.mediaCommunication,
              email: checked,
            },
          }),
      },
      {
        key: 'whatsApp',
        title: translateX('notify_alarm_whatsApp'),
        checked: communication.mediaCommunication.whatsApp,
        onChange: checked =>
          setCommunication({
            ...communication,
            mediaCommunication: {
              ...communication.mediaCommunication,
              whatsApp: checked,
            },
          }),
      },
      {
        key: 'sms',
        title: translateX('notify_alarm_sms'),
        checked: communication.mediaCommunication.sms,
        onChange: checked =>
          setCommunication({
            ...communication,
            mediaCommunication: {
              ...communication.mediaCommunication,
              sms: checked,
            },
          }),
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const ContactModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, refreshDocument, setShowContactsModal, selectedRecord } =
    props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, searchedEntity }) => {
      // Verificação se pelo menos um switch está marcado como true
  const { sms, whatsApp, email } = body?.mediaCommunication || {};

  if (!sms && !whatsApp && !email) {
    appService.notification(
      'w',
      'at_least_one_contact_method_required',
      5,
    );
    return; // interrompe a execução caso nenhum switch esteja ativo
  }

    const url = API_ENTITIES;
    const name = body.name.toLowerCase();
    let entityId = selectedRecord?.entityId;

    if (
      document?.__customer?.moreContacts?.findIndex(
        c => c?.name?.toLowerCase() === name && c?.entityId !== entityId,
      ) > -1
    ) {
      appService.notification(
        'e',
        'check_info',
        'check_info',
        'contact_already_exists',
        5,
      );
      return;
    }

    body.name = toCapitalCase(body.name);
    body.dataType = 'person';
    body.dataProfile = ['contact'];
    body.uniqueKey = name || +new Date();

    if (!searchedEntity) {
      const [doc] = await axiosService.upsert({
        url,
        _id: entityId,
        data: body,
      });
      entityId = doc?._id;
    } else {
      entityId = searchedEntity._id;
    }

    const contact = {
      entityId,
      name: body.name,
      mobile: body?.mobile || '',
      email: body?.email || '',
      dataProfile: body?.dataProfile,
      note: body?.note || '',
      mediaCommunication: {
        sms: body?.mediaCommunication?.sms || false,
        whatsApp: body?.mediaCommunication?.whatsApp || false,
        email: body?.mediaCommunication?.email || false,
      },
    };

    if (!document?.__customer?.moreContacts) {
      document.__customer.moreContacts = [];
    }

    let idx = document.__customer.moreContacts.findIndex(
      c => c?.entityId === entityId,
    );

    if (idx === -1) {
      document.__customer.moreContacts.push(contact);
    } else {
      document.__customer.moreContacts[idx] = {
        ...document.__customer.moreContacts[idx],
        ...contact,
      };
    }

    const axiosOptions = {
      url,
      _id: document?.__customer?._id,
      data: {
        moreContacts: document.__customer.moreContacts,
      },
    };

    try {
      await axiosService.put(axiosOptions);

      refreshDocument();
      setShowContactsModal(false);
    } catch (error) {
      console.error('Error updating more contacts:', error);
    }
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['name', 'mobile', 'email']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
      autoFocus="name"
    >
      <ModalComponent {...props} />
    </JarvislyFormProvider>
  );
});

export default ContactModal;
